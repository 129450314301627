import { D as r } from "./theme-DH267r-1.js";
import { useState as c, useEffect as d } from "react";
const i = (e) => {
  const t = (n) => typeof window < "u" ? window.matchMedia(n).matches : !1, [s, o] = c(t(e));
  function a() {
    o(t(e));
  }
  return d(() => {
    const n = window.matchMedia(e);
    return a(), n.addEventListener("change", a), () => {
      n.removeEventListener("change", a);
    };
  }, [e]), s;
}, m = () => {
  const e = i(r.mobile), t = i(r.tablet), s = i(r.desktop), o = i(r.printer);
  if (e)
    return "mobile";
  if (t)
    return "tablet";
  if (s)
    return "desktop";
  if (o)
    return "printer";
};
export {
  i as a,
  m as u
};
