import { j as t } from "../../jsx-runtime-D0AQ1Mn7.js";
import l from "styled-components";
import n from "clsx";
import { M as r } from "../../theme-DH267r-1.js";
const s = l.div`
  width: 100%;
  height: 100%;
  
  padding: 0;
  margin: 0;
    
  display: grid;
  
  grid-template-columns: 280px auto;
  grid-template-rows: 12px auto;
  grid-template-areas: "sidebar ." "sidebar content";

  @media (max-width: ${r.md}) {
    grid-template-columns: auto;
    grid-template-rows: 64px auto;
    grid-template-areas: "sidebar" "content";
  }

  overflow: hidden;
  
  > div.sidebar-container {
    grid-area: sidebar;

    display: grid;
    

    overflow: hidden;  
  }
  
  > div.content-container {
    grid-area: content;

    display: grid;

    overflow: hidden;

    border-radius: 40px 0 0 0;

    @media (max-width: ${r.md}) {
      border-radius: 0;
    }
  }
  
  &.theme-admin, &.theme-enterprise {
    background-color: ${(e) => e.theme.palette.grays[950].hex};

    > div.sidebar-container {
      color: ${(e) => e.theme.palette.grays[100].hex};
      background-color: ${(e) => e.theme.palette.grays[950].hex};
    }

    > div.content-container {
      color: ${(e) => e.theme.palette.grays[950].hex};
      background-color: ${(e) => e.theme.palette.white.hex};
    }
  }

  &.theme-global {
    background-color: ${(e) => e.theme.palette.white.hex};

    > div.sidebar-container {
      color: ${(e) => e.theme.palette.grays[700].hex};
      background-color: ${(e) => e.theme.palette.white.hex};
      
      > nav {
        color: ${(e) => e.theme.palette.grays[700].hex};
        background-color: ${(e) => e.theme.palette.white.hex};
      }
    }

    > div.content-container {
      color: ${(e) => e.theme.palette.grays[900].hex};
      background-color: ${(e) => e.theme.palette.grays[50].hex};
      border-top-width: 1px;
      border-left-width: 1px;
      border-top-style: solid;
      border-left-style: solid;
      border-top-color: ${(e) => e.theme.palette.grays[200].hex};
      border-left-color: ${(e) => e.theme.palette.grays[200].hex};
    }
  }
`, g = ({ theme: e, sidebar: o, content: a, className: d, ...i }) => /* @__PURE__ */ t.jsxs(s, { ...i, className: n(`theme-${e}`, d), children: [
  /* @__PURE__ */ t.jsx("div", { className: "sidebar-container", children: o }),
  /* @__PURE__ */ t.jsx("div", { className: "content-container", children: a })
] });
export {
  g as Layout,
  g as default
};
